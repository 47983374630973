
.icon-favorite-active {
  transition: all 0.3s;
  color: $red;
  animation: pulse 2s;
  border-radius: 50%;
}

.icon-favorite-inactive {
  transition: all 0.3s;
  color: $light-grey;
}



.icon-validate-active {
  transition: all 0.3s;
  color: $green;
  animation: pulse-green 2s;
  border-radius: 50%;
}

.icon-validate-inactive {
  transition: all 0.3s;
  color: $light-grey;
}

@keyframes pulse {
  0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba($red, 0.3);
  }

  70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba($red, 0);
  }

  100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba($red, 0);
  }
}


@keyframes pulse-green {
  0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba($green, 0.3);
  }

  70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba($green, 0);
  }

  100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba($green, 0);
  }
}