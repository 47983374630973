
$label-width: 40px;

.place-label {
  display: inline-block;
  vertical-align: top;
  width: $label-width;
  color:$light-grey;
}

.place-meta-information {
  display: inline-block;
  width: calc(100% - #{$label-width});
}