.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  .card {
    display: inline-block;
    
  }
}

$opacity: 1;
/*
.card-Essen {
  background-color: rgba($color_essen, $opacity);
}
.card-Trinken {
  background-color: rgba($color_trinken, $opacity);
}
.card-Freizeit {
  background-color: rgba($color_freizeit, $opacity);
}

*/