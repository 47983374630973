.leaflet-container {
  height: 100vh;
  width: 100%;

  .map-small & {
    height: 20vh;
  }
}
.map-small {
  width: 100%;
}

.marker {
  color: rgba(white, 0.6);
  font-size: 1.2rem;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-items: center;
  align-items: center;
  padding: 0 5px;
}

.marker-food {
  background-color: $color_essen;
}
.marker-drink {
  background-color: $color_trinken;
}
.marker-freetime {
  background-color: $color_freizeit;
}