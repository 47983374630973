@import '../style/settings/colors.scss';
$primary: $color_trinken;
$text-muted: $grey-muted;
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../../node_modules/bootstrap/scss/bootstrap-grid.scss';

@import '../style/elements/base.scss';
@import '../style/components/scroll-container.scss';
@import '../style/components/map-container.scss';
@import '../style/components/container.scss';
@import '../style/components/modal-dialog';
@import '../style/components/buttons.scss';
@import '../style/components/icons.scss';
@import '../style/components/burger-menu.scss';
@import '../style/components/place.scss';
@import '../style/components/forms.scss';
@import '../style/utilities/leaflet.scss';
.search-box {
  background-color: $primary;
  color: white;  
}


