.form-control.select-unstyled {
  background-color: transparent;
  color: white;
  border: none;
  border-radius: none;
  border-bottom: 1px solid white;
  option {
    background-color: inherit;
    border: none;
      
  }
}

.form-control.form-control-secondary {
  background-color: transparent;
  border: none;
  border-radius: none;
  border-bottom: 1px solid black;
}

.input-with-icon {
  width: calc(100% - 50px);
}
.input-icon {
  width: 50px;
}