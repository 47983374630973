.bm-burger-button {
  /*position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
  background-color: white;*/
}

.bm-overlay {
  z-index: 1021 !important;
}